import { FeatureFlag, FeatureFlagValue } from '@/plugins/split/FeatureFlag'

// Application FeatureFlag Splits
export const RX_ENABLE_COOKIE_BLOCKING = 'rx-enable-cookie-blocking'
export const RL_3298_MOVE_REDEMPTION_HISTORY = 'RL-3298-move-redemption-history'
export const RL_3391_ALLOW_DELIVERY_OVERRIDE = 'RL-3391-allow-delivery-override'

// Platform FeatureFlag Splits
export const WYNDHAM_MOBILE_DELIVERY_FAST_FOLLOWS = 'wyndham_mobile_delivery_fast_follow'
export const RL_TAX_PRODUCT = 'RL-Tax-Product'
export const LOG_TO_CORALOGIX = 'rlsfe-log-to-coralogix'
export const RL_3625_RENDERLESS_ORDER_COMPONENT = 'RL-3625-renderless-place-order-component'

// "Long Lived" Platform Splits :(
export const RL_PLACE_ORDER_RECAPTCHA = 'RL-Redemption-reCAPTCHA'
export const SVP_RL_HIDE_FOOTER_LINKS = 'SVP-RL-hide-footer-links'
export const RL_HIGH_VOLUME_WARNING = 'RL-high-volume-warning'
export const RX_COUNTRY_FILTER = 'rx-country-filter'
export const CHATBOX_GENERATIVE_AI = 'RL-3336-chatbot-generative-ai'
export const RG_7963_BHMP_PHYSICAL_RESTRICTIONS_RL_RX = 'RG-7963-BHMP-Physical-Restrictions-RL-RX'

/*
SplitIO name constants are to be defined above. Below is the FeatureFlag configuration
for the splitIO treatment. Be sure to configure the local development value when creating
new FeatureFlags. See README for further details.
 */

/** @type {Array<FeatureFlag>} */
export const applicationSplits = [
  // This has to be an application split b/c it's used to conditionally add the
  // attributes to the script tags required by OneTrust.  Those scripts are
  // embedded before we have access to the p-hash for creating the platform
  // split client.
  FeatureFlag.for(CHATBOX_GENERATIVE_AI).withLocalTreatment(FeatureFlagValue.ON),
  FeatureFlag.for(RX_ENABLE_COOKIE_BLOCKING),
  FeatureFlag.for(RL_3298_MOVE_REDEMPTION_HISTORY).withLocalTreatment(FeatureFlagValue.ON),
  FeatureFlag.for(RL_3391_ALLOW_DELIVERY_OVERRIDE).withLocalTreatment(FeatureFlagValue.ON)
]

/** @type {Array<FeatureFlag>} */
export const platformSplits = [
  FeatureFlag.for(RL_PLACE_ORDER_RECAPTCHA),
  FeatureFlag.for(SVP_RL_HIDE_FOOTER_LINKS).withLocalTreatment(FeatureFlagValue.OFF),
  FeatureFlag.for(RL_HIGH_VOLUME_WARNING).withLocalTreatment(FeatureFlagValue.OFF),
  FeatureFlag.for(RX_COUNTRY_FILTER),
  FeatureFlag.for(WYNDHAM_MOBILE_DELIVERY_FAST_FOLLOWS),
  FeatureFlag.for(RG_7963_BHMP_PHYSICAL_RESTRICTIONS_RL_RX).withLocalTreatment(FeatureFlagValue.ON),
  FeatureFlag.for(RL_TAX_PRODUCT).withLocalTreatment(FeatureFlagValue.ON),
  FeatureFlag.for(LOG_TO_CORALOGIX).withLocalTreatment(FeatureFlagValue.OFF),
  FeatureFlag.for(RL_3625_RENDERLESS_ORDER_COMPONENT).withLocalTreatment(FeatureFlagValue.ON)
]
